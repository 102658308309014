import React, { useState, useEffect, useContext } from "react";
import SiteHealthDetail from "../../modals/SiteHealthDetail/SiteHealthDetail";
import { MainContext } from "../../containers/HomePage/HomePage";
import "react-image-gallery/styles/css/image-gallery.css";
import ReportGroups from "../../components/Reports/ReportGroups/ReportGroups";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import { Breadcrumbs, Link, Typography } from "@material-ui/core";
import RightDrawer from "../RightDrawer/RightDrawer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
import AddSiteModal from "../../modals/AddSiteModal/AddSiteModal";
import AddSiteReportModal from "../../modals/AddSiteReportModal/AddSiteReportModal";
import AddHubModal from "../../modals/AddHubModal/AddHubModal";
import AddCameraSimpleModal from "../../modals/AddCameraModal/AddCameraSimpleModal";
import AddAlertChannelModal from "../../modals/AddAlertChannelModal/AddAlertChannelModal";
import AddSharedHubModal from "../../modals/AddSharedHubModal/AddSharedHubModal";
import AddGroupsModal from "../../modals/AddGroupsModal/AddGroupsModal";
import MainExpansionPanel from "../../components/MainExpansionPanel/MainExpansionPanel";
import AddReportGroupModal from "../../modals/AddReportGroupModal/AddReportGroupModal";
import NvrModal from "../../modals/NvrModal/NvrModal";
import { AppStore } from "../../../stores/AppStore";

import Reports from "../../components/Reports/Reports";
import Insights from "../../components/Insights/Insights";

import {
  Org,
  SiteHealth,
  SensorHealth,
  User,
  AlertChannel,
  Site,
  Sensor,
  MainMenu,
  Hub,
  ReportGroup,
  Activity,
  HealthTimelineCount,
  SensorHealthTimelineCount,
} from "../../../services/FunctionService";
import MessageModal from "../../modals/MessageModal/MessageModal";
import CameraMainDrawer from "../../components/CameraMainDrawer/CameraMainDrawer";
import CameraSimpleMainDrawer from "../../components/CameraMainDrawer/CameraSimpleMainDrawer";
import SiteMainDrawer from "../../components/SiteMainDrawer/SiteMainDrawer";
import UserManagementMainDrawer from "../../components/UserManagement/UserManagementMainDrawer/UserManagementMainDrawer";
import UserDetailMainDrawer from "../../components/UserManagement/UserDetailMainDrawer/UserDetailMainDrawer";
import ProfileMainDrawer from "../../components/ProfileMainDrawer/ProfileMainDrawer";
import { useStyles } from "../../styling/global/global";
import Container from "@material-ui/core/Container";
import ConfirmDialog from "../../components/ConfirmDialog/ConfirmDialog";
import EditReportGroupModal from "../../modals/AddReportGroupModal/EditReportGroupModal";
import DashboardMainDrawer from "../../components/DashboardMainDrawer/DashboardMainDrawer";
import SitesMainDrawer from "../../components/SitesMainDrawer/SitesMainDrawer";
import RulesetsMainDrawer from "../../components/RulesetsMainDrawer/RulesetsMainDrawer";
import EditSiteBillingRatesModal from "../../modals/AddSiteModal/EditSiteBillingRatesModal";
import BillingMainContainer from "../../components/Billing/BillingMainContainer/BillingMainContainer";
// import ReviewPage from "../../components/ReviewPage/ReviewPage";
import ReviewPageV3 from "../../components/ReviewPage/ReviewPageV3";
import AlprPage from "../../components/ALPR";
import BIPage from "../../components/BI";

import { SiteHealthStats } from "../SiteHealthStats/SiteHealthStats";
import { FullScreenDialog } from "../SiteActivityImages/FullScreenDialog";
import Resources from "../../components/Resources/Resources";
import GlobalSearch from "../../components/GlobalSearch/GlobalSearch";
import ActivateDynDnsModal from "../../modals/DynDnsModal/ActivateDynDnsModal";
import SetDynDnsModal from "../../modals/DynDnsModal/SetDynDnsModal";
import ViewDynDnsModal from "../../modals/DynDnsModal/ViewDynDnsModal";
import Frontel from "../../modals/Frontel/Frontel";
import Ajax from "../../modals/Ajax/Ajax";
import { TextField, IconButton, InputAdornment, Tooltip } from "@mui/material";
import FileCopyIcon from "@mui/icons-material/FileCopy";

export const CopyField = ({ value }: any) => {
  // Copy function to copy the text to clipboard
  const handleCopy = () => {
    if (value) {
      navigator.clipboard.writeText(value).then(
        () => {
          console.log("Text copied to clipboard");
          // Optionally, you can show a success message here
        },
        (err) => {
          console.error("Failed to copy: ", err);
        }
      );
    }
  };

  return (
    <TextField
      label=""
      value={value}
      fullWidth
      style={{ marginBottom: 10, marginTop: 5 }}
      InputProps={{
        readOnly: true, // Make the input read-only
        endAdornment: (
          <InputAdornment position="end">
            <Tooltip title="Copy to clipboard">
              <IconButton onClick={handleCopy}>
                <FileCopyIcon />
              </IconButton>
            </Tooltip>
          </InputAdornment>
        ),
      }}
    />
  );
};

interface MockEvent {
  (event: Event): any;
}
interface MainDrawerProps {
  appStore?: AppStore;
  setCurrentOrg: (d: any) => void;
  currentOrg: Org;
  mainMenu: MainMenu;
  changeMainMenu: (d: MainMenu) => void;
  authhandler: MockEvent;
  search: boolean;
  closeSearch: () => void;
  ajax: boolean;
  closeAjax: () => void;
}

const MainDrawer: React.FC<MainDrawerProps> = ({
  appStore,
  authhandler,
  setCurrentOrg,
  currentOrg,
  mainMenu,
  changeMainMenu,
  search,
  closeSearch,
  ajax,
  closeAjax,
}: MainDrawerProps) => {
  const classes = useStyles();
  const [refresh, setRefresh] = useState(0);
  const [orgs, setOrgs] = useState<{ [key: number]: Org } | undefined>();
  const [sensorhealth, setSensorHealth] = useState<SensorHealth | undefined>(
    undefined
  );
  const [healthsites, setHealthSites] = useState<SiteHealth[] | undefined>(
    undefined
  );
  const { setShowGraph, showGraph } = useContext(MainContext);
  const [activity, setActivity] = useState<Activity | undefined>({
    alert_counts: [],
    inference_counts: [],
  });
  const [healthtimeline, setHealthTimeLine] = useState<
    HealthTimelineCount[] | undefined
  >(undefined);
  const [sensorhealthtimeline, setSensorHealthTimeline] = useState<
    SensorHealthTimelineCount[] | undefined
  >(undefined);
  const [alertchannel, setAlertChannel] = useState<AlertChannel | undefined>(
    undefined
  );
  const [site, setSite] = useState<Site | undefined>(undefined);
  const [editingsite, setEditingSite] = useState<Site | undefined>(undefined);
  const [hub, setHub] = useState<Hub | undefined>(undefined);
  const [sensor, setSensor] = useState<Sensor | undefined>(undefined);
  const [reportgroups, setReportGroups] = useState<ReportGroup[] | undefined>(
    undefined
  );
  const [reportgroupsForWholeTree, setReportGroupsForWholeTree] = useState<
    ReportGroup[] | undefined
  >(undefined);
  const [reportgroupsForTopOrg, setReportGroupsForTopOrg] = useState<
    ReportGroup[] | undefined
  >(undefined);
  const [user, setUser] = useState<User | undefined>(undefined);
  const [reload, setReload] = useState<any>(0);
  const [message, setMessage] = useState("");
  const [popmessage, setPopMessage] = useState("");
  const [selectedItem, setCurrentSelectedItem] = React.useState<{
    [key: string]: any;
  }>({});
  const [loadingmain, setLoading] = React.useState<boolean>(false);
  const [popelements, setPopElements] = React.useState<
    React.ReactNode[] | undefined
  >(undefined);
  const [editingreportgroup, setEditingReportGroup] = useState<
    ReportGroup | undefined
  >(undefined);
  const [editreportgroupmodalopen, setEditReportGroupModalOpen] =
    useState(false);
  // Full Screen Modal
  const [showModal, setShowModal] = useState(false);
  const [showSiteHealthModal, setSiteHealthModal] = useState(false);
  const [healthParams, setHealthParams] = useState<(string | number)[]>([]);
  // End
  const [confirmReportGroupOpen, setConfirmReportGroupOpen] = useState(false);
  const [deletereportgroupid, setDeleteReportGroupId] = useState(0);
  const [confirmmessage, setConfirmMessage] = useState("");
  const [interval, setInter] = useState<number>(4);
  const [endTime, setEndTime] = useState<number>(120);
  const [getdetailorgs, setGetDetailOrgs] = React.useState<boolean>(false);
  let loading = true;
  const [sitehealthdetail, setSiteHealthDetail] = useState<boolean>(false);
  const [siteVal, setSiteVal] = useState<number>();

  const [startTimeFilter, setStartTimeFilter] = useState<number>(0);
  const [endTimeFilter, setEndTimeFilter] = useState<number>(0);

  const controllerRef = React.useRef<AbortController | null>();

  const [healthSitesFetching, setHealthSitesFetching] = useState(false);
  let orgsFetching = true;
  // if ((orgs && healthsites) || site) {
  if (orgs || site) {
    loading = false;
  }
  const [notesResultsArray, setNotesResultsArray] = React.useState<string[]>(
    []
  );
  const [epoch_seconds, setEpoch_Seconds] = useState(0);

  const ctx = React.useContext(MainContext);

  const StyledTableRow = withStyles((theme: Theme) =>
    createStyles({
      root: {
        "&:nth-of-type(odd)": {
          backgroundColor: theme.palette.action.hover,
        },
      },
    })
  )(TableRow);

  const siteHealthDetail = () => {
    setSiteHealthDetail(true);
  };
  const closeSiteHealthDetail = () => {
    setSiteHealthDetail(false);
  };

  useEffect(() => {
    const setData = async () => {
      if (currentOrg) {
        setOrgs(currentOrg.orgs);
        setGetDetailOrgs(true);
      }
    };
    setData();
  }, [currentOrg]);

  const siteHealthModal = (params: (string | number)[], site_id?: number) => {
    let time = params[0].toString();
    let epocSeconds = 0;
    let day = new Date().getDate();
    let month = new Date().getMonth();
    let year = new Date().getFullYear();

    if (time.length > 5) {
      let timeArray = time.split(" ");
      let dateSplit = timeArray[0].split("/");
      let timeSplit = timeArray[1].split(":");

      month = parseInt(dateSplit[1]) - 1;
      day = parseInt(dateSplit[0]);
      let hour = parseInt(timeSplit[0]);
      let minutes = parseInt(timeSplit[1]);

      let d = new Date(year, month, day, hour, minutes);
      epocSeconds = Math.round(d.getTime() / 1000);
    } else {
      let timeSplit = time.split(":");
      let hour = parseInt(timeSplit[0]);
      let minutes = parseInt(timeSplit[1]);
      let d = new Date(year, month, day, hour, minutes);
      epocSeconds = Math.round(d.getTime() / 1000);
    }

    if (site_id) {
      setSiteVal(site_id);
      setEpoch_Seconds(epocSeconds);
      siteHealthDetail();
    } else {
      setStartTimeFilter(epocSeconds);
      setEndTimeFilter(epocSeconds + 120);
      setSiteHealthModal(true);
    }
  };

  const showFullModalHandler = (params: (string | number)[]) => {
    if (
      appStore &&
      appStore.authService &&
      appStore.authService.getRoles() &&
      appStore.authService.getRoles().indexOf("site_admin") >= 0
    ) {
      setHealthParams(params);
      setShowModal((prev) => !prev);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const token = await appStore?.authService.getAuthorisedToken();
      if (token && currentOrg) {
        const reportgroups = await appStore?.functionService.getReportGroups(
          token,
          currentOrg.org_id
        );
        if (reportgroups) {
          setReportGroups(reportgroups);
        }
        const reportgroupstoporg =
          await appStore?.functionService.getReportGroupsForTopOrg(
            token,
            currentOrg.org_id
          );
        if (reportgroupstoporg) {
          setReportGroupsForTopOrg(reportgroupstoporg);
        }
        if (currentOrg.org_id) {
          const reportgroupsforwholetree =
            await appStore?.functionService.getReportGroupsFromCurrentLevelUp(
              token,
              currentOrg?.org_id
            );
          if (reportgroupsforwholetree) {
            setReportGroupsForWholeTree(reportgroupsforwholetree);
          }
        }
      }
    };
    fetchData();
  }, [currentOrg]);

  useEffect(() => {
    const fetchData = async () => {
      const token = await appStore?.authService.getAuthorisedToken();
      if (token && currentOrg) {
        let healthforsites = [];
        if (!site) {
          healthforsites = await appStore?.functionService.getHealthForOrg(
            token,
            currentOrg.org_id
          );
        } else {
          healthforsites = await appStore?.functionService.getHealthForSites(
            token,
            site!.site_id,
            currentOrg.org_id
          );
        }

        if (healthforsites) {
          setHealthSites(healthforsites);
        }
        setLoading(false);
      }
    };
    fetchData();
    setHealthSitesFetching(false);
    const refresh = setInterval(() => fetchData(), 1000 * 60 * 2);
    return () => clearInterval(refresh);
  }, [currentOrg?.org_id, site?.site_id]);

  useEffect(() => {
    const setData = async () => {
      if (currentOrg && orgs && getdetailorgs) {
        setGetDetailOrgs(false);
        appStore?.helpers.getOrgDetail(
          appStore,
          orgs,
          currentOrg.org_id,
          getOrgsFromServer
        );
      }
    };
    setData();
  }, [getdetailorgs]);
  const getOrgsFromServer = (orgs: { [key: number]: Org }) => {
    setOrgs(orgs);
    setRefresh(new Date().getTime());
  };

  const fetchOrgAlertActivity = async () => {
    if (controllerRef.current) {
      controllerRef.current.abort();
    }
    const controller = new AbortController();
    controllerRef.current = controller;
    const token = await appStore?.authService.getAuthorisedToken();
    if (token && currentOrg && currentOrg.org_id != undefined && selectedItem) {
      if (selectedItem.type == "org" || selectedItem.type == undefined) {
        let now = new Date();
        let hours = convertIntervalToHours(interval);
        const activity =
          await appStore?.functionService.fetchAlertActivityStats(
            token,
            currentOrg.org_id,
            now.getTime() / 1000 - 3600 * hours,
            hours * 60,
            undefined,
            null,
            null,
            controllerRef.current?.signal
          );
        if (activity.success) {
          setActivity((prev: Activity | any) => {
            return {
              ...prev,
              alert_counts: activity.msg.alert_counts,
            };
          });
        }
        const health = await appStore?.functionService.getHealthTimeLineForOrg(
          token,
          currentOrg.org_id,
          now.getTime() / 1000 - 3600 * hours,
          hours * 60,
          controllerRef.current?.signal
        );
        if (health.success) {
          setHealthTimeLine(health.msg);
        }
        controllerRef.current = null;
      }
    }
  };

  const fetchOrgInferenceActivity = async () => {
    if (controllerRef.current) {
      controllerRef.current.abort();
    }
    const controller = new AbortController();
    controllerRef.current = controller;
    const token = await appStore?.authService.getAuthorisedToken();
    if (token && currentOrg && currentOrg.org_id != undefined && selectedItem) {
      if (selectedItem.type == "org" || selectedItem.type == undefined) {
        let now = new Date();
        let hours = convertIntervalToHours(interval);
        const activity =
          await appStore?.functionService.fetchAInferenceActivityStats(
            token,
            currentOrg.org_id,
            now.getTime() / 1000 - 3600 * hours,
            hours * 60,
            undefined,
            null,
            null,
            controllerRef.current?.signal
          );
        if (activity.success) {
          setActivity((prev: Activity | any) => {
            return {
              ...prev,
              inference_counts: activity.msg.inference_counts,
            };
          });
        }
        const health = await appStore?.functionService.getHealthTimeLineForOrg(
          token,
          currentOrg.org_id,
          now.getTime() / 1000 - 3600 * hours,
          hours * 60,
          controllerRef.current?.signal
        );
        if (health.success) {
          setHealthTimeLine(health.msg);
        }
        controllerRef.current = null;
      }
    }
  };

  const fetchAlertData = async () => {
    if (controllerRef.current) {
      controllerRef.current.abort();
    }
    const controller = new AbortController();
    controllerRef.current = controller;
    const token = await appStore?.authService.getAuthorisedToken();
    if (token && selectedItem) {
      if (selectedItem.type == "site") {
        let now = new Date();
        let hours = convertIntervalToHours(interval);
        const activity =
          await appStore?.functionService.fetchAlertActivityStats(
            token,
            null,
            now.getTime() / 1000 - 3600 * hours,
            hours * 60,
            undefined,
            `[${selectedItem.site_id}]`,
            null,
            controllerRef.current?.signal
          );
        if (activity.success) {
          setActivity((prev: Activity | any) => {
            return {
              ...prev,
              alert_counts: activity.msg.alert_counts,
            };
          });
        }
        const health =
          await appStore?.functionService.getHealthTimeLineForSites(
            token,
            [selectedItem.site_id],
            now.getTime() / 1000 - 3600 * hours,
            hours * 60,
            controllerRef.current?.signal
          );
        if (health.success) {
          setSensorHealthTimeline(undefined);
          setHealthTimeLine(health.msg);
        }
      }
      if (selectedItem.type == "sensor") {
        let now = new Date();
        let hours = convertIntervalToHours(interval);
        const activity =
          await appStore?.functionService.fetchAlertActivityStats(
            token,
            null,
            now.getTime() / 1000 - 3600 * hours,
            hours * 60,
            undefined,
            null,
            `[${selectedItem.sensor_id}]`,
            controllerRef.current?.signal
          );
        if (activity.success) {
          setActivity((prev: Activity | any) => {
            return {
              ...prev,
              alert_counts: activity.msg.alert_counts,
            };
          });
        }
        const sensorhealthtimeline =
          await appStore?.functionService.getHealthTimeLineForSensor(
            token,
            selectedItem.site_id,
            selectedItem.sensor_id_str,
            now.getTime() / 1000 - 3600 * hours,
            hours * 60,
            controllerRef.current?.signal
          );
        if (sensorhealthtimeline.success) {
          setHealthTimeLine(undefined);
          setSensorHealthTimeline(sensorhealthtimeline.msg);
        }
        controllerRef.current = null;
      }
    }
  };

  const fetchInferenceData = async () => {
    if (controllerRef.current) {
      controllerRef.current.abort();
    }
    const controller = new AbortController();
    controllerRef.current = controller;
    const token = await appStore?.authService.getAuthorisedToken();
    if (token && selectedItem) {
      if (selectedItem.type == "site") {
        let now = new Date();
        let hours = convertIntervalToHours(interval);
        const activity =
          await appStore?.functionService.fetchAInferenceActivityStats(
            token,
            null,
            now.getTime() / 1000 - 3600 * hours,
            hours * 60,
            undefined,
            `[${selectedItem.site_id}]`,
            null,
            controllerRef.current?.signal
          );
        if (activity.success) {
          setActivity((prev: Activity | any) => {
            return {
              ...prev,
              inference_counts: activity.msg.inference_counts,
            };
          });
        }
        const health =
          await appStore?.functionService.getHealthTimeLineForSites(
            token,
            [selectedItem.site_id],
            now.getTime() / 1000 - 3600 * hours,
            hours * 60,
            controllerRef.current?.signal
          );
        if (health.success) {
          setSensorHealthTimeline(undefined);
          setHealthTimeLine(health.msg);
        }
      }
      if (selectedItem.type == "sensor") {
        let now = new Date();
        let hours = convertIntervalToHours(interval);
        const activity =
          await appStore?.functionService.fetchAInferenceActivityStats(
            token,
            null,
            now.getTime() / 1000 - 3600 * hours,
            hours * 60,
            undefined,
            null,
            `[${selectedItem.sensor_id}]`,
            controllerRef.current?.signal
          );
        if (activity.success) {
          setActivity((prev: Activity | any) => {
            return {
              ...prev,
              inference_counts: activity.msg.inference_counts,
            };
          });
        }
        const sensorhealthtimeline =
          await appStore?.functionService.getHealthTimeLineForSensor(
            token,
            selectedItem.site_id,
            selectedItem.sensor_id_str,
            now.getTime() / 1000 - 3600 * hours,
            hours * 60,
            controllerRef.current?.signal
          );
        if (sensorhealthtimeline.success) {
          setHealthTimeLine(undefined);
          setSensorHealthTimeline(sensorhealthtimeline.msg);
        }
      }
      controllerRef.current = null;
    }
  };

  useEffect(() => {
    setActivity({ alert_counts: [], inference_counts: [] });
    fetchOrgAlertActivity();
    fetchOrgInferenceActivity();
    const refresh = setInterval(() => {
      fetchOrgAlertActivity();
      fetchOrgInferenceActivity();
    }, 1000 * 60 * 2);
    return () => clearInterval(refresh);
  }, [currentOrg, interval]);

  useEffect(() => {
    fetchAlertData();
    fetchInferenceData();
    const refresh = setInterval(() => {
      fetchAlertData();
      fetchInferenceData();
    }, 1000 * 60 * 2);
    return () => clearInterval(refresh);
  }, [selectedItem, interval]);

  useEffect(() => {
    const fetchOrg = async () => {
      const token = await appStore?.authService.getAuthorisedToken();
      if (token && currentOrg) {
        const notesResults = await appStore?.functionService.getNotes(
          token,
          currentOrg.org_id
        );
        if (notesResults?.success) {
          setNotesResultsArray(
            notesResults.predefined_notes.alert_web_interface_notes
          );
        }
      }
      if (token && currentOrg && reload !== 0) {
        const org = await appStore?.functionService.getOrgOneLevel(
          token,
          currentOrg.org_id
        );
        if (org) {
          setCurrentOrg(org);
          setSelectedItem(org, false);
          setOrgs(org.orgs);
          setGetDetailOrgs(true);
          //this is to make sure screen refresh when a new report group gets created
          const reportgroups = await appStore?.functionService.getReportGroups(
            token,
            currentOrg.org_id
          );
          if (reportgroups) {
            setReportGroups(reportgroups);
          }
          const reportgroupstoporg =
            await appStore?.functionService.getReportGroupsForTopOrg(
              token,
              currentOrg.org_id
            );
          if (reportgroups) {
            setReportGroupsForTopOrg(reportgroupstoporg);
          }
          if (currentOrg.org_id) {
            const reportgroupsforwholetree =
              await appStore?.functionService.getReportGroupsFromCurrentLevelUp(
                token,
                currentOrg.org_id
              );
            if (reportgroupsforwholetree) {
              setReportGroupsForWholeTree(reportgroupsforwholetree);
            }
          }
        }
      }
    };
    fetchOrg();
  }, [reload]);

  function convertIntervalToHours(interval: number) {
    let hours = 2;
    if (interval === 17) {
      hours = 4;
    }
    if (interval === 29) {
      hours = 8;
    }
    if (interval === 44) {
      hours = 12;
    }
    if (interval === 62) {
      hours = 24;
    }
    if (interval === 84) {
      hours = 48;
    }
    if (interval === 110) {
      hours = 168;
    }
    if (interval === 148) {
      hours = 336;
    }
    return hours;
  }

  async function saveNewOrg(org_name: string) {
    const token = await appStore?.authService.getAuthorisedToken();
    if (token) {
      let new_org = await appStore?.functionService.createOrg(
        token,
        currentOrg.org_id,
        org_name,
        ""
      );
      if (new_org) {
        if (new_org.success) {
          closeAddGroupModal();
          // let tempOrg: Org = currentOrg;
          // tempOrg.type = 'org';
          setPopMessage("Created new Group  " + org_name);
          openMessageModal();
          setReload(new Date().getTime());
        } else {
          setMessage(new_org.msg);
        }
      }
    }
  }

  async function saveNewReportGroup(name: string, recipients: string[]) {
    closeAddReportGroupModal();
    const token = await appStore?.authService.getAuthorisedToken();
    if (token) {
      let new_report_group = await appStore?.functionService.createReportGroup(
        token,
        currentOrg.org_id,
        name,
        recipients
      );
      if (new_report_group) {
        if (new_report_group.success) {
          setPopMessage("Created new Report Group " + name);
          openMessageModal();
          setReload(new Date().getTime());
        } else {
          setPopMessage(new_report_group.msg);
          openMessageModal();
          setReload(new Date().getTime());
        }
      }
    }
  }
  async function updateReportGroup(
    report_group_id: number,
    name: string,
    recipients: string[]
  ) {
    setEditReportGroupModalOpen(false);
    const token = await appStore?.authService.getAuthorisedToken();
    if (token) {
      let update_report = await appStore?.functionService.modifyReportGroup(
        token,
        report_group_id,
        name,
        recipients
      );
      if (update_report) {
        if (update_report.success) {
          setPopMessage("Updated report group");
          openMessageModal();
          setReload(new Date().getTime());
        } else {
          setPopMessage(update_report.msg);
          openMessageModal();
        }
      }
    }
  }
  async function deleteReportGroup() {
    setConfirmReportGroupOpen(false);
    const token = await appStore?.authService.getAuthorisedToken();
    if (token) {
      let delete_report_group =
        await appStore?.functionService.deleteReportGroup(
          token,
          deletereportgroupid
        );
      if (delete_report_group) {
        if (delete_report_group.success) {
          setPopMessage("Deleted report group");
          openMessageModal();
          setReload(new Date().getTime());
        } else {
          setPopMessage(delete_report_group.msg);
          openMessageModal();
        }
      }
    }
  }
  const deleteOrgFunc = async (org: Org) => {
    const token = await appStore?.authService.getAuthorisedToken();
    if (token) {
      setLoading(true);
      const editOrgResult = await appStore?.functionService.deleteOrg(
        token,
        org?.org_id
      );
      if (editOrgResult) {
        if (editOrgResult?.success) {
          let uniqueArr = [];
          appStore?.authService.getBreadCrumbs()?.map((org, index) => {
            org.type = "org";
            uniqueArr.push(org);
            if (index === appStore?.authService.getBreadCrumbs()!.length - 2) {
              setSelectedItem(org, false);
            }
          });
          setPopMessage("Group successfully deleted");
          openMessageModal();
          setRefresh(new Date().getTime());
          setLoading(false);
        } else {
          setPopMessage(editOrgResult.msg);
          openMessageModal();
        }
      }
      setLoading(false);
    }
  };
  const EnableOrgFunc = async (org: Org) => {
    const token = await appStore?.authService.getAuthorisedToken();
    if (token) {
      setLoading(true);
      const editOrgResult = await appStore?.functionService.EnableOrg(
        token,
        org?.org_id,
        org?.enabled === true ? false : true
      );
      if (editOrgResult) {
        if (editOrgResult?.success) {
          let uniqueArr = [];
          appStore?.authService.getBreadCrumbs()?.map((org, index) => {
            org.type = "org";
            uniqueArr.push(org);
            if (index === appStore?.authService.getBreadCrumbs()!.length - 1) {
              setSelectedItem(org, false);
            }
          });
          setPopMessage(
            `Group successfully ${
              org?.enabled === true ? "disabled" : "enabled"
            }`
          );
          openMessageModal();
          setRefresh(new Date().getTime());
          setLoading(false);
        } else {
          setPopMessage(editOrgResult.msg);
          openMessageModal();
          setLoading(false);
        }
      }
    }
  };
  const editOrgFunc = async (org_id: number, org_name: string) => {
    const token = await appStore?.authService.getAuthorisedToken();
    if (token) {
      setLoading(true);
      const editOrgResult = await appStore?.functionService.editOrg(
        token,
        org_id,
        org_name
      );
      if (editOrgResult) {
        if (editOrgResult?.success) {
          let uniqueArr = [];
          appStore?.authService.getBreadCrumbs()?.map((org, index) => {
            org.type = "org";
            uniqueArr.push(org);
            if (index === appStore?.authService.getBreadCrumbs()!.length - 1) {
              setSelectedItem(org, false);
            }
          });
          setPopMessage("Group name successfully edited");
          openMessageModal();
          setRefresh(new Date().getTime());
          setLoading(false);
        } else {
          setPopMessage(editOrgResult.msg);
          openMessageModal();
          setLoading(false);
        }
      }
    }
  };
  async function renameSiteName(site: Site, name: string) {
    const token = await appStore?.authService.getAuthorisedToken();
    setLoading(true);
    if (token) {
      let rename = await appStore?.functionService.modifySiteName(
        token,
        site.site_id,
        name
      );
      if (rename) {
        if (rename.success) {
          let tempOrg: Org = currentOrg;
          tempOrg.type = "org";
          setSelectedItem(tempOrg, false);
          setReload(new Date().getTime());
          setLoading(false);
          setPopMessage("Site name successfully changed");
          openMessageModal();
        } else {
          setLoading(false);
          setPopMessage(rename.msg);
          openMessageModal();
        }
      }
    }
  }

  async function trainingSettings(
    site: Site,
    training_fraction: number,
    training_triggers_only: boolean
  ) {
    const token = await appStore?.authService.getAuthorisedToken();
    setLoading(true);
    if (token) {
      let rename = await appStore?.functionService.modifySiteTrainingSettings(
        token,
        site.site_id,
        training_fraction,
        training_triggers_only
      );
      if (rename) {
        if (rename.success) {
          // setSelectedItem(site, false);
          setReload(new Date().getTime());
          setLoading(false);
          setPopMessage("Site training settings changed");
          openMessageModal();
          openMessageModal();
        } else {
          setLoading(false);
          setPopMessage(rename.msg);
          openMessageModal();
        }
      }
    }
  }

  async function saveNewVirtualSite(
    site_name: string,
    billing_rate_id: number,
    timezone: string,
    frontelOrg: boolean,
    ajaxOrg: boolean,
    ajaxHubId: string
  ) {
    setLoading(true);
    const token = await appStore?.authService.getAuthorisedToken();
    if (token) {
      let new_site = await appStore?.functionService.createSiteLogicalHub(
        token,
        currentOrg.org_id,
        site_name,
        billing_rate_id,
        frontelOrg,
        ajaxOrg,
        ajaxHubId,
        timezone
      );
      closeAddSiteModal();

      console.log("new_site: ", new_site);

      if (new_site) {
        if (!new_site?.success) {
          setPopMessage("Error creating new Site " + new_site.msg);
          return openMessageModal();
        }
        let site = await appStore?.functionService.getSite(
          token,
          new_site.site_id
        );
        if (site) {
          if (site.data_origin === "alert_smtp_client") {
            await appStore?.functionService.createNewSmtpPassword(
              token,
              new_site.site_id,
              true
            );
          }

          if (
            site.data_origin === "alert_smtp_client" ||
            site.data_origin.includes("frontel") ||
            site.data_origin.includes("ajax")
          ) {
            // let new_alert_channel =
            //   await appStore?.functionService.createAlertChannel(
            //     token,
            //     "en_ZA",
            //     site.site_name + " Alerts",
            //     site.site_id,
            //     timezone,
            //     site.data_origin
            //   );
            // if (new_alert_channel) {
            //   setReload(new Date().getTime());
            //   setPopMessage(
            //     "New Site with an alertchannel created for " + site_name
            //   );
            //   setLoading(false);
            //   openMessageModal();
            // } else {
            //   setLoading(false);
            //   setPopMessage("Error creating new Site " + new_site.msg);
            //   openMessageModal();
            // }
            setReload(new Date().getTime());
            setPopMessage("Created new Site " + site_name);
            setLoading(false);
            openMessageModal();
          } else {
            if (new_site.success) {
              setReload(new Date().getTime());
              setPopMessage("Created new Site " + site_name);
              setLoading(false);
              openMessageModal();
            } else {
              setLoading(false);
              setPopMessage("Error creating new Site " + new_site.msg);
              openMessageModal();
            }
          }
        }
      } else {
        setPopMessage("Error creating new Site");
        openMessageModal();
      }
    }
  }

  async function saveNewSite(
    site_name: string,
    createhub: boolean,
    org_billing_rate_id: number,
    timezone: string,
    frontelOrg: boolean,
    ajaxOrg: boolean,
    ajaxHubId: string
  ) {
    const token = await appStore?.authService.getAuthorisedToken();
    if (token) {
      setPopMessage("");

      let new_site = await appStore?.functionService.createSite(
        token,
        currentOrg.org_id,
        site_name,
        org_billing_rate_id,
        false,
        0,
        frontelOrg,
        ajaxOrg,
        ajaxHubId
      );
      if (new_site) {
        if (new_site.success) {
          closeAddSiteModal();
          let site_channel = await appStore?.functionService.getSite(
            token,
            new_site.site_id
          );
          let new_alert_channel =
            await appStore?.functionService.createAlertChannel(
              token,
              "en_ZA",
              site_name + " Alerts",
              new_site.site_id,
              timezone,
              site_channel?.data_origin ? site_channel.data_origin : "default"
            );
          if (new_alert_channel) {
            if (new_alert_channel.success) {
              if (createhub) {
                const hub_id_str =
                  await appStore?.functionService.getNewHubIDString(token);
                if (hub_id_str) {
                  let new_hub_id = await appStore?.functionService.createHub(
                    token,
                    currentOrg.org_id,
                    hub_id_str,
                    site_name,
                    new_site.site_id
                  );
                  if (new_hub_id) {
                    if (new_hub_id.success) {
                      var newtoken =
                        await appStore?.functionService.getNewHubActivationToken(
                          token,
                          hub_id_str,
                          false
                        );
                      setMessage("");
                      if (newtoken?.success) {
                        var tokeninfo: React.ReactNode[] = [];
                        tokeninfo.push(
                          <>
                            <Typography className={classes.bold_text}>
                              {"Created new Site: " +
                                site_name +
                                " and Hub: " +
                                hub_id_str}
                            </Typography>
                            <Typography className={classes.bold_text}>
                              {"Code Expiry: " + newtoken?.code_expiry}
                            </Typography>
                            {newtoken?.hub_activated && (
                              <Typography className={classes.bold_text}>
                                {"Hub Activated: " + newtoken?.hub_activated}
                              </Typography>
                            )}
                            <Typography className={classes.bold_text}>
                              {/* {"Activation code: " + newtoken?.activation_code} */}
                              {"Activation code: "}{" "}
                              <CopyField value={newtoken?.activation_code} />
                            </Typography>
                          </>
                        );
                        if (
                          appStore!
                            .authService!.getRoles()!
                            .indexOf("sys_admin") >= 0
                        ) {
                          tokeninfo.push(
                            <>
                              {newtoken?.message_server && (
                                <Typography className={classes.bold_text}>
                                  {"Message Server: " +
                                    newtoken?.message_server}
                                </Typography>
                              )}
                              {newtoken?.vpn_key_loaded && (
                                <Typography className={classes.bold_text}>
                                  {"VPN Key Loaded: " +
                                    newtoken?.vpn_key_loaded}
                                </Typography>
                              )}
                            </>
                          );
                        }
                        setPopElements(tokeninfo);
                      } else {
                        setPopMessage(
                          "Created new Site: " +
                            site_name +
                            " and Hub: " +
                            hub_id_str +
                            " token creation failed with: " +
                            newtoken?.msg
                        );
                      }
                      openMessageModal();
                    }
                  }
                }
              } else {
                setPopMessage("Created new Site " + site_name);
                openMessageModal();
              }
              var newsite = {
                type: "site",
                site_id: new_site.site_id,
                name: site_name,
              };
              setSelectedItem(newsite, false);
            } else {
              setMessage(new_alert_channel.msg);
            }
          }
        } else {
          setMessage(new_site.msg);
        }
      } else {
        setMessage("something went wrong, could not create site");
      }
    }
  }
  async function saveNewAlertChannel(
    locale: string,
    alert_channel_name: string,
    site_id: number,
    timezone: string,
    data_origin: string
  ) {
    setLoading(true);
    closeAddAlertChannelModal();
    const token = await appStore?.authService.getAuthorisedToken();
    if (token) {
      let new_alert_channel =
        await appStore?.functionService.createAlertChannel(
          token,
          locale,
          alert_channel_name,
          site_id,
          timezone,
          data_origin
        );
      if (new_alert_channel) {
        if (new_alert_channel.success) {
          setPopMessage("Created new Alert Ruleset " + alert_channel_name);
          openMessageModal();
          setLoading(false);
        } else {
          setPopMessage(
            "Error creating new Alert Ruleset " + new_alert_channel.msg
          );
          openMessageModal();
          setLoading(false);
        }
      }
    }
  }
  async function saveNewSharedHub(location: string) {
    const token = await appStore?.authService.getAuthorisedToken();
    if (token) {
      const hub_id_str = await appStore?.functionService.getNewHubIDString(
        token
      );
      if (hub_id_str) {
        let new_hub = await appStore?.functionService.createHub(
          token,
          currentOrg.org_id,
          hub_id_str,
          location,
          0
        );
        if (new_hub) {
          if (new_hub.success) {
            var newtoken =
              await appStore?.functionService.getNewHubActivationToken(
                token,
                hub_id_str,
                false
              );
            setMessage("");
            closeAddSharedHubModal();
            if (newtoken?.success) {
              var tokeninfo: React.ReactNode[] = [];
              tokeninfo.push(
                <>
                  <Typography className={classes.bold_text}>
                    {"Created new Hub: " + hub_id_str}
                  </Typography>
                  <Typography className={classes.bold_text}>
                    {"Code Expiry: " + newtoken?.code_expiry}
                  </Typography>
                  {newtoken?.hub_activated && (
                    <Typography className={classes.bold_text}>
                      {"Hub Activated: " + newtoken?.hub_activated}
                    </Typography>
                  )}
                  <Typography className={classes.bold_text}>
                    {/* {"Activation code: " + newtoken?.activation_code} */}
                    {"Activation code: "}{" "}
                    <CopyField value={newtoken?.activation_code} />
                  </Typography>
                </>
              );
              if (
                appStore!.authService!.getRoles()!.indexOf("sys_admin") >= 0
              ) {
                tokeninfo.push(
                  <>
                    <Typography className={classes.bold_text}>
                      {"Message Server: " + newtoken?.message_server}
                    </Typography>
                    <Typography className={classes.bold_text}>
                      {"VPN Key Loaded: " + newtoken?.vpn_key_loaded}
                    </Typography>
                  </>
                );
              }
              setPopElements(tokeninfo);
            } else {
              setPopMessage(
                "Created new Shared Hub: " +
                  hub_id_str +
                  " token creation failed with: " +
                  newtoken?.msg
              );
            }
            openMessageModal();
            setReload(new Date().getTime());
          } else {
            setMessage(new_hub.msg);
          }
        }
      }
    }
  }

  async function saveNewHub(
    location: string,
    site_id: number,
    type_str: string
  ) {
    const token = await appStore?.authService.getAuthorisedToken();
    if (token) {
      closeAddHubModal();
      const hub_id_str = await appStore?.functionService.getNewHubIDString(
        token
      );
      if (hub_id_str) {
        let new_hub = await appStore?.functionService.createHub(
          token,
          currentOrg.org_id,
          hub_id_str,
          location,
          site_id
        );
        if (new_hub) {
          if (new_hub.success) {
            var newtoken =
              await appStore?.functionService.getNewHubActivationToken(
                token,
                hub_id_str,
                false
              );
            setMessage("");
            if (newtoken?.success) {
              var tokeninfo: React.ReactNode[] = [];
              tokeninfo.push(
                <>
                  <Typography className={classes.bold_text}>
                    {"Created new Hub: " + hub_id_str}
                  </Typography>
                  <Typography className={classes.bold_text}>
                    {/* {"Activation code: " + newtoken?.activation_code} */}
                    {"Activation code: "}{" "}
                    <CopyField value={newtoken?.activation_code} />
                  </Typography>
                  <Typography className={classes.bold_text}>
                    {"Code Expiry: " + newtoken?.code_expiry}
                  </Typography>
                  {newtoken?.hub_activated && (
                    <Typography className={classes.bold_text}>
                      {"Hub Activated: " + newtoken?.hub_activated}
                    </Typography>
                  )}
                </>
              );
              if (
                appStore!.authService!.getRoles()!.indexOf("sys_admin") >= 0
              ) {
                tokeninfo.push(
                  <>
                    <Typography className={classes.bold_text}>
                      {"Message Server: " + newtoken?.message_server}
                    </Typography>
                    <Typography className={classes.bold_text}>
                      {"VPN Key Loaded: " + newtoken?.vpn_key_loaded}
                    </Typography>
                  </>
                );
              }
              setPopElements(tokeninfo);
            } else {
              setPopMessage(
                "Created new Hub: " +
                  hub_id_str +
                  " token creation failed with: " +
                  newtoken?.msg
              );
            }

            openMessageModal();
            setReload(new Date().getTime());
          } else {
            setMessage(new_hub.msg);
          }
        }
      }
    }
  }
  async function saveNewCamera(
    hub_id: number,
    site_id: number,
    human_name: string,
    config: string,
    current_env: string = "None",
    isCameraMate?: boolean
  ) {
    try {
      const token = await appStore?.authService.getAuthorisedToken();
      if (token) {
        setLoading(true);
        setPopMessage("Loading...");
        let new_camera = await appStore?.functionService.createSensor(
          token,
          hub_id,
          site_id,
          human_name,
          config,
          current_env
        );
        if (new_camera) {
          if (new_camera.success) {
            let hub_force = await appStore?.functionService.forceHubUpdate(
              token,
              hub_id
            );
            setMessage("");
            if (hub_force?.success) {
              if (isCameraMate) {
                setPopMessage("Created new Camera");
              } else {
                setPopMessage(
                  "Created new Camera and sucessfully sent refresh message to hub"
                );
              }
            } else {
              setPopMessage(
                "Created new Camera but could not send refresh message to hub: " +
                  hub_force?.msg
              );
            }
            openMessageModal();
          } else {
            setPopMessage(new_camera.msg);
            openMessageModal();
          }
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }
  async function saveNewCameraSimple(
    hub_id: number,
    site_id: number,
    human_name: string,
    data_origin_name: string,
    site_name: string,
    password: string,
    current_env: string = "None"
  ) {
    const token = await appStore?.authService.getAuthorisedToken();
    if (token) {
      closeAddCameraSimpleModal();
      setLoading(true);
      let new_camera = await appStore?.functionService.createSensorSimple(
        token,
        hub_id,
        site_id,
        human_name,
        current_env
      );
      setLoading(false);
      if (new_camera) {
        if (new_camera.success) {
          setPopMessage("Created new Camera");
          let popbody: React.ReactNode[] = [];

          if (data_origin_name === "alert_smtp_client") {
            let newAlertSmtp =
              await appStore?.functionService.createNewSmtpPassword(
                token,
                site_id,
                true
              );
            let sensors = await appStore?.functionService.getSensorsForSite(
              token,
              site_id,
              "thumbnail"
            );
            if (newAlertSmtp) {
              if (newAlertSmtp.success) {
                if (sensors) {
                  site_name = site_name.split(" ").join("_");
                  let poprows: React.ReactNode[] = [];
                  poprows.push(
                    <StyledTableRow>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                    </StyledTableRow>
                  );
                  popbody.push(
                    <TableContainer component={Paper}>
                      <Table size="small">
                        <TableBody>
                          <Typography className={classes.bold_text}>
                            Successful : Please see camera details for
                            connection parameters.
                          </Typography>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  );
                  popbody.push(<div className={classes.space_smallest}></div>);
                  popbody.push(<div className={classes.space_smallest}></div>);
                }
              }
            }
          }
          setPopElements(popbody);
          openMessageModal();
        } else {
          setPopMessage(new_camera.msg);
          openMessageModal();
        }
      }
    }
  }
  async function saveNewSiteReport(
    site_id: number,
    report_group_id: number,
    report_type: string,
    daily_utc_schedule: string
  ) {
    closeAddSiteReportModal();
    setLoading(true);
    const token = await appStore?.authService.getAuthorisedToken();
    if (token) {
      let new_user = await appStore?.functionService.createSiteReport(
        token,
        site_id,
        report_group_id,
        report_type,
        daily_utc_schedule
      );
      setLoading(false);
      if (new_user) {
        if (new_user.success) {
          setPopMessage("Created new Site Report");
          openMessageModal();
        } else {
          setPopMessage(new_user.msg);
          openMessageModal();
        }
      }
    }
  }
  async function updateSiteBillingRate(
    site_id: number,
    billing_rate_id: number
  ) {
    closeSiteBillingRatesModal();
    setLoading(true);
    const token = await appStore?.authService.getAuthorisedToken();
    if (token) {
      let updatesite = await appStore?.functionService.modifySiteProduct(
        token,
        site_id,
        billing_rate_id
      );
      setLoading(false);
      if (updatesite) {
        if (updatesite.success) {
          setPopMessage("Modified site product");
          openMessageModal();
          setReload(new Date().getTime());
        } else {
          setPopMessage(updatesite.msg);
          openMessageModal();
        }
      }
    }
  }
  async function updateSiteUseEmailTime(
    site_id: number,
    use_email_time: boolean
  ) {
    closeSiteBillingRatesModal();
    setLoading(true);
    const token = await appStore?.authService.getAuthorisedToken();
    if (token) {
      let updatesite = await appStore?.functionService.modifySiteUseEmailTime(
        token,
        site_id,
        use_email_time
      );

      if (updatesite) {
        if (updatesite.success) {
          setPopMessage("Modified site product");
          openMessageModal();
          setReload(new Date().getTime());
        } else {
          setPopMessage(updatesite.msg);
          openMessageModal();
        }
      }
      setLoading(false);
    }
  }
  //Catch all "select item"
  const setSelectedItem = async (item: any, editing: boolean) => {
    if (item.type === "site") {
      if (mainMenu.navigator === false) {
        mainMenu.navigator = true;
        mainMenu.reports = false;
        mainMenu.sites = false;
        mainMenu.usereditor = false;
        mainMenu.dashboard = false;
        mainMenu.tests = false;
        mainMenu.review = false;
        mainMenu.review3 = false;
        changeMainMenu(mainMenu);
      }

      setAlertChannel(undefined);
      setSensor(undefined);
      setHub(undefined);
      setUser(undefined);
      let healthsite = healthsites?.find((x) => x.site_id === item.site_id);
      if (healthsite) {
        let newhealthsites: SiteHealth[] | undefined = [];
        newhealthsites.push(healthsite);
        setHealthSites(newhealthsites);
      }
      setSensorHealth(undefined);
      setSite(item);
    }
    if (item.type === "alertchannel") {
      setSensor(undefined);
      setUser(undefined);
      setHub(undefined);
      setAlertChannel(item);
    }
    if (item.type === "sensor") {
      setAlertChannel(undefined);
      setUser(undefined);
      setHub(undefined);
      let healthsite = healthsites?.find(
        (x: SiteHealth) => x.site_id === item.site_id
      );
      if (healthsite) {
        let healthsensor = healthsite.sensors?.find(
          (x) => x.sensor_id === item.sensor_id
        );
        if (healthsensor) {
          setSensorHealth(healthsensor);
        }
      }
      setSensor(item);
    }
    if (item.type === "hub") {
      setAlertChannel(undefined);
      setUser(undefined);
      setSensor(undefined);
      setSite(undefined);
      setHub(item);
    }
    if (item.type === "user") {
      if (mainMenu.navigator === false) {
        mainMenu.navigator = false;
        mainMenu.reports = false;
        mainMenu.sites = false;
        mainMenu.usereditor = true;
        mainMenu.dashboard = false;
        mainMenu.rulesets = false;
        mainMenu.tests = false;
        mainMenu.review = false;
        mainMenu.review3 = false;
        changeMainMenu(mainMenu);
      }
      setSite(undefined);
      setAlertChannel(undefined);
      setSensor(undefined);
      setHub(undefined);
      setUser(item);
    }
    if (item.type === "org") {
      setAlertChannel(undefined);
      setSite(undefined);
      setSensor(undefined);
      setHub(undefined);
      setUser(undefined);
      setHealthSites(undefined);
      setSensorHealth(undefined);
      setOrgs(undefined);
      mainMenu.dashboard = false;
      mainMenu.navigator = true;
      mainMenu.reports = false;
      mainMenu.usereditor = false;
      mainMenu.sites = false;
      mainMenu.rulesets = false;
      mainMenu.site_reports = false;
      mainMenu.tests = false;
      mainMenu.review = false;
      mainMenu.review3 = false;
      setCurrentOrg(item);
    }
    setCurrentSelectedItem(item);
  };
  //Open EditReportGroup
  const openEditReportGroupModal = (reportgroup: ReportGroup) => {
    setEditingReportGroup(reportgroup);
    setEditReportGroupModalOpen(true);
  };
  const closeEditReportGroupModal = () => {
    setMessage("");
    setEditReportGroupModalOpen(false);
  };
  //MessageModal
  const [messagemodalopen, setMessageModalOpen] = React.useState(false);

  const openMessageModal = () => {
    setMessageModalOpen(true);
  };

  const closeMessageModal = () => {
    setPopElements([]);
    setMessage("");
    setPopMessage("");
    setMessageModalOpen(false);
  };

  // AddSiteModal
  const [siteopen, setOpenAddSiteModal] = React.useState(false);

  const openAddSiteModal = () => {
    setOpenAddSiteModal(true);
  };

  const closeAddSiteModal = () => {
    setMessage("");
    setOpenAddSiteModal(false);
  };

  // EditSiteBillingRatesModal
  const [sitebillingratesopen, setSiteBillingRatesModal] =
    React.useState(false);
  const [openSiteNvr, setOpenSiteNvr] = React.useState(false);
  const [openActivateDynDns, setOpenActivateDynDns] = React.useState(false);
  const [openSetDynDns, setOpenSetDynDns] = React.useState(false);
  const [openViewDynDns, setOpenViewDynDns] = React.useState(false);
  const [siteFrontel, setSiteFrontel] = React.useState(false);

  const openSiteBillingRatesModal = (site: Site) => {
    setEditingSite(site);
    setSiteBillingRatesModal(true);
  };
  const openSiteFrontel = (site: Site) => {
    setEditingSite(site);
    setSiteFrontel(true);
  };
  const openSiteNvrModal = (sensor: Sensor, site: Site) => {
    setEditingSite(site);

    setOpenSiteNvr(true);
  };

  const closeSiteNvrModal = () => {
    setMessage("");
    setOpenSiteNvr(false);
  };

  const openActivateDynDnsModal = (sensor: Sensor, site: Site) => {
    setEditingSite(site);

    setOpenActivateDynDns(true);
  };

  const openSetDynDnsModal = (sensor: Sensor, site: Site) => {
    setEditingSite(site);

    setOpenSetDynDns(true);
  };

  const openViewDynDnsModal = (sensor: Sensor, site: Site) => {
    setEditingSite(site);

    setOpenViewDynDns(true);
  };

  const closeSiteDynDnsModal = () => {
    setMessage("");
    setOpenActivateDynDns(false);
    setOpenSetDynDns(false);
    setOpenViewDynDns(false);
  };

  const closeSiteFrontel = () => {
    setMessage("");
    setSiteFrontel(false);
  };

  const closeSiteBillingRatesModal = () => {
    setMessage("");
    setSiteBillingRatesModal(false);
  };

  // AddAletChannelModal
  const [alertchannelopen, setOpenAddAlertChannelModal] = React.useState(false);

  const openAddAlertChannelModal = () => {
    setOpenAddAlertChannelModal(true);
  };

  const closeAddAlertChannelModal = () => {
    setMessage("");
    setOpenAddAlertChannelModal(false);
  };

  //AddGroupModal
  const [groupopen, setGroupOpen] = React.useState(false);

  const openAddGroupModal = () => {
    setGroupOpen(true);
  };

  const closeAddGroupModal = () => {
    setMessage("");
    setGroupOpen(false);
  };
  //Add report group modal
  const [reportgroupmodalopen, setAddReportGroupModalOpen] =
    React.useState(false);
  const openAddReportGroupModal = () => {
    setAddReportGroupModalOpen(true);
  };
  const closeAddReportGroupModal = () => {
    setMessage("");
    setAddReportGroupModalOpen(false);
  };
  //AddHubModal
  const [hubopen, setHubOpen] = React.useState(false);

  const openAddHubModal = () => {
    setHubOpen(true);
  };

  const toogleFullScreenModal = () => {
    setShowModal((prev) => !prev);
  };

  const toogleSiteHealthScreenModal = () => {
    setSiteHealthModal((prev) => !prev);
  };

  const closeAddHubModal = () => {
    setMessage("");
    setHubOpen(false);
  };
  //AddSiteModal
  const [addsitereportopen, setAddSiteReportOpen] = React.useState(false);
  const openAddSiteReportModal = () => {
    setAddSiteReportOpen(true);
  };

  const closeAddSiteReportModal = () => {
    setMessage("");
    setAddSiteReportOpen(false);
  };

  //AddCameraSimpleModal
  const [camerasimpleopen, setCameraSimpleOpen] = React.useState(false);

  const openAddCameraSimpleModal = () => {
    setCameraSimpleOpen(true);
  };

  const closeAddCameraSimpleModal = () => {
    setMessage("");
    setCameraSimpleOpen(false);
  };
  //AddSharedHubModal
  const [sharedhubopen, setSharedHubOpen] = React.useState(false);

  const openAddSharedHubModal = () => {
    setSharedHubOpen(true);
  };

  const closeAddSharedHubModal = () => {
    setMessage("");
    setSharedHubOpen(false);
  };

  //interval change on graph on right drawer
  const handleIntervalChange = (newinterval: number) => {
    setInter(newinterval);
    if (newinterval === 2) {
      setEndTime(120);
    }
    if (newinterval === 17) {
      setEndTime(240);
    }
    if (newinterval === 29) {
      setEndTime(480);
    }
    if (newinterval === 44) {
      setEndTime(720);
    }
    if (newinterval === 62) {
      setEndTime(1440);
    }
    if (newinterval === 84) {
      setEndTime(2880);
    }
    if (newinterval === 110) {
      setEndTime(10080);
    }
    if (newinterval === 148) {
      setEndTime(20160);
    }
  };

  const openConfirmDeleteReportGroup = (
    report_group_id: number,
    report_group_name: string
  ) => {
    setDeleteReportGroupId(report_group_id);
    setConfirmMessage(
      "Are you sure you want to delete " + report_group_name + "?"
    );
    setConfirmReportGroupOpen(true);
  };

  const graphHandler = (e: boolean) => {
    setShowGraph(e);
  };

  if (currentOrg) {
    //Create the breadcrumbs from the list of orgs in authService.breadcrumbs which is Org[]
    var breadcrumbcontainer: React.ReactNode[] = [];
    if (mainMenu.navigator) {
      var breadcrumbs: React.ReactNode[] = [];
      appStore?.authService.getBreadCrumbs()?.forEach((org) => {
        org.type = "org";
        breadcrumbs.push(
          <Link
            className={classes.bold_text}
            href="#"
            onClick={() => {
              setSelectedItem(org, false);
              ctx.clearRulesState();
            }}
          >
            {org.org_name}
          </Link>
        );
      });
      breadcrumbcontainer.push(
        <div className="bread-crum-container">
          <Breadcrumbs
            aria-label="breadcrumb"
            style={{ color: "red" }}
            className="breadcrumb"
            separator={<Typography color="primary">/</Typography>}
          >
            <Link color="inherit" href="#">
              <div className="home-icon">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g opacity="0.87">
                    <path
                      d="M7.99301 1.33331C4.31301 1.33331 1.33301 4.31998 1.33301 7.99998C1.33301 11.68 4.31301 14.6666 7.99301 14.6666C11.6797 14.6666 14.6663 11.68 14.6663 7.99998C14.6663 4.31998 11.6797 1.33331 7.99301 1.33331ZM12.613 5.33331H10.6463C10.433 4.49998 10.1263 3.69998 9.72634 2.95998C10.953 3.37998 11.973 4.23331 12.613 5.33331ZM7.99967 2.69331C8.55301 3.49331 8.98634 4.37998 9.27301 5.33331H6.72634C7.01301 4.37998 7.44634 3.49331 7.99967 2.69331ZM2.83967 9.33331C2.73301 8.90665 2.66634 8.45998 2.66634 7.99998C2.66634 7.53998 2.73301 7.09331 2.83967 6.66665H5.09301C5.03967 7.10665 4.99967 7.54665 4.99967 7.99998C4.99967 8.45331 5.03967 8.89331 5.09301 9.33331H2.83967ZM3.38634 10.6666H5.35301C5.56634 11.5 5.87301 12.3 6.27301 13.04C5.04634 12.62 4.02634 11.7733 3.38634 10.6666V10.6666ZM5.35301 5.33331H3.38634C4.02634 4.22665 5.04634 3.37998 6.27301 2.95998C5.87301 3.69998 5.56634 4.49998 5.35301 5.33331V5.33331ZM7.99967 13.3066C7.44634 12.5066 7.01301 11.62 6.72634 10.6666H9.27301C8.98634 11.62 8.55301 12.5066 7.99967 13.3066ZM9.55967 9.33331H6.43967C6.37967 8.89331 6.33301 8.45331 6.33301 7.99998C6.33301 7.54665 6.37967 7.09998 6.43967 6.66665H9.55967C9.61967 7.09998 9.66634 7.54665 9.66634 7.99998C9.66634 8.45331 9.61967 8.89331 9.55967 9.33331ZM9.72634 13.04C10.1263 12.3 10.433 11.5 10.6463 10.6666H12.613C11.973 11.7666 10.953 12.62 9.72634 13.04V13.04ZM10.9063 9.33331C10.9597 8.89331 10.9997 8.45331 10.9997 7.99998C10.9997 7.54665 10.9597 7.10665 10.9063 6.66665H13.1597C13.2663 7.09331 13.333 7.53998 13.333 7.99998C13.333 8.45998 13.2663 8.90665 13.1597 9.33331H10.9063Z"
                      fill="#15325F"
                      fill-opacity="0.87"
                    />
                  </g>
                </svg>
              </div>
            </Link>
            {breadcrumbs}
          </Breadcrumbs>
        </div>
      );
    }

    var addstream: React.ReactNode[] = [];
    if (selectedItem && selectedItem["type"] === "site") {
      addstream.push(
        <AddCameraSimpleModal
          currentOrg={currentOrg}
          site={site}
          appStore={appStore}
          open={camerasimpleopen}
          onClose={closeAddCameraSimpleModal}
          saveNewCameraSimple={(
            hub_id: number,
            site_id: number,
            human_name: string,
            data_origin_name: string,
            site_name: string,
            password: string,
            current_env: string
          ) =>
            saveNewCameraSimple(
              hub_id,
              site_id,
              human_name,
              data_origin_name,
              site_name,
              password,
              current_env
            )
          }
        />
      );
    }
    var cameramaindrawer: React.ReactNode[] = [];
    //if sensor is selected select on product type which type of sensor main drawer to display
    if (sensor) {
      if (
        selectedItem.data_origin === "alert_rest_client" ||
        selectedItem.data_origin === "alert_smtp_client" ||
        selectedItem.data_origin === "alert_ftp_client" ||
        selectedItem.data_origin === "inference_api_client"
      ) {
        cameramaindrawer.push(
          <>
            <CameraSimpleMainDrawer
              interval={interval}
              sensorhealth={sensorhealth}
              selectedItem={selectedItem}
              currentOrg={currentOrg}
              handleIntervalChange={handleIntervalChange}
              mainMenu={mainMenu}
              showFullModalHandler={showFullModalHandler}
              site={site}
              sensor={sensor}
              appStore={appStore}
              setSelectedItem={(item: any) => setSelectedItem(item, false)}
            ></CameraSimpleMainDrawer>
          </>
        );
      } else {
        cameramaindrawer.push(
          <>
            <CameraMainDrawer
              interval={interval}
              currentOrg={currentOrg}
              selectedItem={selectedItem}
              handleIntervalChange={handleIntervalChange}
              mainMenu={mainMenu}
              showFullModalHandler={showFullModalHandler}
              site={site}
              sensor={sensor}
              sensorhealth={sensorhealth}
              appStore={appStore}
              setSelectedItem={(item: any) => setSelectedItem(item, false)}
              siteHealthModal={siteHealthModal}
            ></CameraMainDrawer>
          </>
        );
      }
    }
    return (
      <div className={classes.root}>
        {showModal && (
          <FullScreenDialog
            showModal={showModal}
            toogleFullScreenModal={toogleFullScreenModal}
            healthParams={healthParams}
            appStore={appStore}
            endTime={endTime}
            setSelectedItem={setSelectedItem}
          />
        )}
        <SiteHealthStats
          setSelectedItem={setSelectedItem}
          start_time_filter={startTimeFilter}
          end_time_filter={endTimeFilter}
          top_org_id={currentOrg.org_id}
          showModal={showSiteHealthModal}
          toogleFullScreenModal={toogleSiteHealthScreenModal}
          healthParams={healthParams}
          appStore={appStore}
          loading_main={setLoading}
        />
        <CssBaseline />
        {reload}
        <main
          className={
            !mainMenu.rulesets
              ? !mainMenu.billing ||
                !mainMenu.insights ||
                !mainMenu.dashboard ||
                !showGraph
                ? !mainMenu.tests
                  ? classes.content
                  : classes.tests_content
                : classes.billing_content
              : classes.ruleset_content
          }
        >
          {breadcrumbcontainer}
          {search && (
            <GlobalSearch
              appStore={appStore!}
              setSelectedItem={setSelectedItem}
              openModal={search}
              closeModal={closeSearch}
            />
          )}
          {ajax && (
            <Ajax
              appStore={appStore!}
              openModal={ajax}
              closeModal={closeAjax}
            />
          )}
          {/*************Decision on what main drawer to show based on value of mainMenu*****************/}
          {mainMenu.profile ? (
            <ProfileMainDrawer
              authhandler={authhandler}
              appStore={appStore!}
            ></ProfileMainDrawer>
          ) : mainMenu.rulesets ? (
            <RulesetsMainDrawer appStore={appStore!}></RulesetsMainDrawer>
          ) : mainMenu.billing ? (
            <BillingMainContainer appStore={appStore} />
          ) : mainMenu.site_reports ? (
            // <BillingMainContainer appStore={appStore} />
            <Reports appStore={appStore} />
          ) : mainMenu.dashboard ? (
            <DashboardMainDrawer
              setSelectedItem={setSelectedItem}
              appStore={appStore!}
              currentOrg={currentOrg}
              mainMenu={mainMenu}
              setIntervalvalue={handleIntervalChange}
              showFullModalHandler={showFullModalHandler}
              siteHealthModal={siteHealthModal}
            >
              {/* {!site && ( */}
              {/* <p>test </p> */}
              {!site &&
                showGraph &&
                !mainMenu.rulesets &&
                mainMenu.dashboard &&
                !mainMenu.insights &&
                !mainMenu.site_reports &&
                !mainMenu.billing &&
                !mainMenu.tests && (
                  <div
                    style={{
                      width: 650,
                      alignContent: "center",
                      paddingTop: 30,
                    }}
                  >
                    <RightDrawer
                      isDashboard={true}
                      interval={interval}
                      mid={currentOrg.org_id} // Top Org Id
                      type={"top_org_id"}
                      activity={activity}
                      sensorhealthtimeline={sensorhealthtimeline}
                      healthtimeline={healthtimeline}
                      health={healthsites}
                      sensorhealth={sensorhealth}
                      mainMenu={mainMenu}
                      setIntervalvalue={handleIntervalChange}
                      showFullModalHandler={showFullModalHandler}
                      siteHealthModal={siteHealthModal}
                    ></RightDrawer>
                  </div>
                )}
              {/* )} */}
            </DashboardMainDrawer>
          ) : mainMenu.insights ? (
            <Insights appStore={appStore!}></Insights>
          ) : // : mainMenu.review ? (
          //   <ReviewPage
          //     appStore={appStore!}
          //     setSelectedItem={setSelectedItem}
          //   />
          // )
          mainMenu.review3 ? (
            <ReviewPageV3
              appStore={appStore!}
              setSelectedItem={setSelectedItem}
            />
          ) : mainMenu.alpr ? (
            <AlprPage appStore={appStore!} setSelectedItem={setSelectedItem} />
          ) : mainMenu.bi ? (
            <BIPage appStore={appStore!} />
          ) : mainMenu.sites ? (
            <SitesMainDrawer
              setSelectedItem={setSelectedItem}
              appStore={appStore!}
              currentOrg={currentOrg}
            ></SitesMainDrawer>
          ) : mainMenu.usereditor ? (
            user ? (
              <UserDetailMainDrawer
                authhandler={authhandler}
                setUser={(x: any) => setUser(x)}
                appStore={appStore}
                user={user}
              ></UserDetailMainDrawer>
            ) : (
              <UserManagementMainDrawer
                appStore={appStore}
                setSelectedItem={(item: any) => setSelectedItem(item, false)}
              ></UserManagementMainDrawer>
            )
          ) : mainMenu.reports ? (
            <h1>Reports</h1>
          ) : mainMenu.tests ? (
            <Resources />
          ) : loadingmain ? (
            <Container maxWidth="md">
              <div className="wait-container">
                <svg width="48px" height="48px" viewBox="0 0 128 128">
                  <g>
                    <linearGradient id="linear-gradient">
                      <stop offset="0%" stop-color="#ffffff" />
                      <stop offset="100%" stop-color="#22305f" />
                    </linearGradient>
                    <path
                      d="M63.85 0A63.85 63.85 0 1 1 0 63.85 63.85 63.85 0 0 1 63.85 0zm.65 19.5a44 44 0 1 1-44 44 44 44 0 0 1 44-44z"
                      fill="url(#linear-gradient)"
                      fill-rule="evenodd"
                    />
                    <animateTransform
                      attributeName="transform"
                      type="rotate"
                      from="0 64 64"
                      to="360 64 64"
                      dur="1080ms"
                      repeatCount="indefinite"
                    ></animateTransform>
                  </g>
                </svg>
              </div>
            </Container>
          ) : (
            <div className="main-component-wrapper">
              {sensor ? (
                cameramaindrawer
              ) : site ? (
                <SiteMainDrawer
                  interval={interval}
                  handleIntervalChange={handleIntervalChange}
                  mainMenu={mainMenu}
                  showFullModalHandler={showFullModalHandler}
                  selectedItem={selectedItem}
                  saveNewCamera={saveNewCamera}
                  health={healthsites}
                  reportgroups={reportgroupsForWholeTree!}
                  currentOrg={currentOrg}
                  orgsite={site}
                  appStore={appStore}
                  setSelectedItem={(item: any) => setSelectedItem(item, false)}
                  openAddAlertChannelModal={openAddAlertChannelModal}
                  openAddHubModal={openAddHubModal}
                  openAddCameraSimpleModal={openAddCameraSimpleModal}
                  openAddSiteReportModal={openAddSiteReportModal}
                  siteHealthModal={siteHealthModal}
                ></SiteMainDrawer>
              ) : (
                <MainExpansionPanel
                  notesResultsArray={notesResultsArray}
                  EnableOrgFunc={(org: Org) => EnableOrgFunc(org)}
                  renameSiteName={(site: Site, name: string) => {
                    renameSiteName(site, name);
                  }}
                  editOrgFunc={(org_id: number, org_name: string) =>
                    editOrgFunc(org_id, org_name)
                  }
                  deleteOrgFunc={(org: Org) => deleteOrgFunc(org)}
                  trainingSettings={(
                    site: Site,
                    training_fraction: number,
                    training_triggers_only: boolean
                  ) => {
                    trainingSettings(
                      site,
                      training_fraction,
                      training_triggers_only
                    );
                  }}
                  openConfirmDeleteReportGroup={(
                    report_group_id: number,
                    name: string
                  ) => openConfirmDeleteReportGroup(report_group_id, name)}
                  setEditReportGroupModalOpen={(
                    reportgroupsForTopOrg: ReportGroup
                  ) => openEditReportGroupModal(reportgroupsForTopOrg)}
                  reportgroups={reportgroupsForTopOrg!}
                  openAddReportGroupModal={openAddReportGroupModal}
                  appStore={appStore}
                  loading={loading}
                  orgs={orgs!}
                  sensor={sensor!}
                  currentOrg={currentOrg}
                  sites={currentOrg?.sites}
                  healthsites={healthsites!}
                  hubs={currentOrg?.hubs}
                  openAddGroupModal={openAddGroupModal}
                  openAddSiteModal={openAddSiteModal}
                  openSiteBillingRatesModal={openSiteBillingRatesModal}
                  openSiteNvrModal={openSiteNvrModal}
                  openActivateDynDnsModal={openActivateDynDnsModal}
                  openSetDynDnsModal={openSetDynDnsModal}
                  openViewDynDnsModal={openViewDynDnsModal}
                  openAddSharedHubModal={openAddSharedHubModal}
                  setSelectedItem={(item: any, editing: boolean) =>
                    setSelectedItem(item, editing)
                  }
                  openSiteFrontel={openSiteFrontel}
                ></MainExpansionPanel>
              )}
            </div>
          )}
          {/**********************************************************************************************/}
        </main>

        {/****************************************  Right Hand Drawer  *********************************/}
        {!site &&
          showGraph &&
          !mainMenu.rulesets &&
          !mainMenu.dashboard &&
          !mainMenu.insights &&
          !mainMenu.site_reports &&
          !mainMenu.billing &&
          !mainMenu.tests && (
            <Drawer
              className={classes.drawer}
              variant="permanent"
              classes={{
                paper: classes.drawerPaper,
              }}
              anchor="right"
            >
              <Divider />
              <RightDrawer
                interval={interval}
                mid={currentOrg.org_id} // Top Org Id
                type={"top_org_id"}
                activity={activity}
                sensorhealthtimeline={sensorhealthtimeline}
                healthtimeline={healthtimeline}
                health={healthsites}
                sensorhealth={sensorhealth}
                setIntervalvalue={handleIntervalChange}
                mainMenu={mainMenu}
                showFullModalHandler={showFullModalHandler}
                siteHealthModal={siteHealthModal}
              ></RightDrawer>
            </Drawer>
          )}
        {/*********************************************************************************************/}

        {/****************************************  Modal POPUS  **************************************/}
        <SiteHealthDetail
          site_id={siteVal}
          appStore={appStore}
          onClose={closeSiteHealthDetail}
          open={sitehealthdetail}
          start_time_filter={epoch_seconds}
        ></SiteHealthDetail>
        <AddReportGroupModal
          appStore={appStore}
          saveNewReportGroup={(name: string, recipients: string[]) =>
            saveNewReportGroup(name, recipients)
          }
          open={reportgroupmodalopen}
          currentOrg={currentOrg}
          message={message}
          onClose={closeAddReportGroupModal}
        />
        <EditReportGroupModal
          appStore={appStore}
          reportgroup={editingreportgroup!}
          updateReportGroup={(report_group_id, name, recipients) =>
            updateReportGroup(report_group_id, name, recipients)
          }
          open={editreportgroupmodalopen}
          message={""}
          onClose={closeEditReportGroupModal}
        />
        <AddGroupsModal
          appStore={appStore}
          currentOrg={currentOrg}
          message={message}
          open={groupopen}
          onClose={closeAddGroupModal}
          saveNewOrg={(org_name: string) => saveNewOrg(org_name)}
        />
        <AddSiteModal
          open={siteopen}
          appStore={appStore}
          message={message}
          onClose={closeAddSiteModal}
          currentOrg={currentOrg}
          saveNewSite={(
            site_name: string,
            createhub: boolean,
            org_billing_rate_id: number,
            timezone: string,
            frontelOrg: boolean,
            ajaxOrg: boolean,
            ajaxHubId: string
          ) =>
            saveNewSite(
              site_name,
              createhub,
              org_billing_rate_id,
              timezone,
              frontelOrg,
              ajaxOrg,
              ajaxHubId
            )
          }
          saveNewVirtualSite={(
            site_name: string,
            billing_rate_id: number,
            timezone: string,
            frontelOrg: boolean,
            ajaxOrg: boolean,
            ajaxHubId: string
          ) =>
            saveNewVirtualSite(
              site_name,
              billing_rate_id,
              timezone,
              frontelOrg,
              ajaxOrg,
              ajaxHubId
            )
          }
        />
        <AddSiteReportModal
          currentOrg={currentOrg}
          reportgroups={reportgroupsForWholeTree!}
          site={site!}
          open={addsitereportopen}
          appStore={appStore}
          message={message}
          onClose={closeAddSiteReportModal}
          saveNewSiteReport={(
            site_id: number,
            report_group_id: number,
            report_type: string,
            daily_utc_schedule: string
          ) =>
            saveNewSiteReport(
              site_id,
              report_group_id,
              report_type,
              daily_utc_schedule
            )
          }
        />
        <EditSiteBillingRatesModal
          site={editingsite}
          open={sitebillingratesopen}
          appStore={appStore}
          message={message}
          onClose={closeSiteBillingRatesModal}
          updateSiteBillingRate={(site_id: number, billing_rate_id: number) =>
            updateSiteBillingRate(site_id, billing_rate_id)
          }
        />
        <AddAlertChannelModal
          selectedItem={selectedItem}
          open={alertchannelopen}
          site={site}
          message={message}
          onClose={closeAddAlertChannelModal}
          currentOrg={currentOrg}
          saveNewAlertChannel={(
            locale: string,
            alert_channel_name: string,
            site_id: number,
            timezone: string,
            data_origin: string
          ) =>
            saveNewAlertChannel(
              locale,
              alert_channel_name,
              site_id,
              timezone,
              data_origin
            )
          }
          appStore={appStore}
        />
        <AddHubModal
          appStore={appStore}
          site={site}
          open={hubopen}
          message={message}
          onClose={closeAddHubModal}
          saveNewHub={(location: string, site_id: number, type_str: string) =>
            saveNewHub(location, site_id, type_str)
          }
        />
        {addstream}
        <AddSharedHubModal
          open={sharedhubopen}
          message={message}
          onClose={closeAddSharedHubModal}
          currentOrg={currentOrg}
          saveNewSharedHub={(location: string) => saveNewSharedHub(location)}
        />
        <MessageModal
          open={messagemodalopen}
          onClose={closeMessageModal}
          message={popmessage}
          elements={popelements}
        />
        <ConfirmDialog
          title=""
          open={confirmReportGroupOpen}
          setOpen={setConfirmReportGroupOpen}
          onConfirm={deleteReportGroup}
        >
          {confirmmessage}
        </ConfirmDialog>
        <NvrModal
          open={openSiteNvr}
          onClose={closeSiteNvrModal}
          updateSiteUseEmailTime={(
            site_id: number,
            use_email_time: boolean
          ) => {
            updateSiteUseEmailTime(site_id, use_email_time);
          }}
          appStore={appStore}
          sensor={sensor}
          site={editingsite}
        />
        <ActivateDynDnsModal
          open={openActivateDynDns}
          onClose={closeSiteDynDnsModal}
          appStore={appStore}
          site={editingsite}
        />
        <SetDynDnsModal
          open={openSetDynDns}
          onClose={closeSiteDynDnsModal}
          appStore={appStore}
          site={editingsite}
        />
        <Frontel
          open={siteFrontel}
          appStore={appStore}
          org={undefined}
          site={editingsite}
          hub={hub}
          onClose={closeSiteFrontel}
        />
        <ViewDynDnsModal
          open={openViewDynDns}
          onClose={closeSiteDynDnsModal}
          appStore={appStore}
          site={editingsite}
        />
      </div>
    );
  } else {
    return (
      <Container maxWidth="md">
        <div className="wait-container">
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <svg width="48px" height="48px" viewBox="0 0 128 128">
            <g>
              <linearGradient id="linear-gradient">
                <stop offset="0%" stop-color="#ffffff" />
                <stop offset="100%" stop-color="#22305f" />
              </linearGradient>
              <path
                d="M63.85 0A63.85 63.85 0 1 1 0 63.85 63.85 63.85 0 0 1 63.85 0zm.65 19.5a44 44 0 1 1-44 44 44 44 0 0 1 44-44z"
                fill="url(#linear-gradient)"
                fill-rule="evenodd"
              />
              <animateTransform
                attributeName="transform"
                type="rotate"
                from="0 64 64"
                to="360 64 64"
                dur="1080ms"
                repeatCount="indefinite"
              ></animateTransform>
            </g>
          </svg>
        </div>
        <div style={{ display: "none" }}>
          <ReportGroups setReloadHandler={setReload}></ReportGroups>
        </div>
      </Container>
    );
  }
};

export default MainDrawer;
